<template>
    <div>
    <div v-show="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <div v-show="!isBusy" ref="viewContainer" v-if="vehicle">
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="vehicle.name">{{vehicle.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>Vehicle name</h3>
           
            <md-button class="md-raised" @click="addPicture">Add image</md-button>
            <!--md-button class="md-primary">Save vehicle type</md-button-->
            <md-button class="md-primary md-raised" @click="fireUpdate">Update vehicle</md-button>
        </md-toolbar>
        <md-tabs 
            class="fixed-tab-selector" 
            md-alignment="left" 
            @md-changed="mainTabChanged" 
            ref="mainTabs" 
            :md-active-tab="mainActiveTab" 
            :md-dynamic-height="true"
            :class="{ 'hide-desktop-tabs': dialogWidth < 992}"
            > 
            <template slot="md-tab" slot-scope="{ tab }"> 
                {{ tab.label }} 
                <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
                <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
            </template>
            <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
                <CRow :gutters="true">
                    <CCol sm="12" lg="5">
                        <md-card>
                            <md-card-header>
                                <div class="md-title">Vehicle info</div>
                            </md-card-header>

                            <md-card-content>
                        
                                <CRow>
                                    <CCol sm="12">
                                        <md-field :class="getValidationClass('type')">
                                            <label>Name</label>
                                            <md-input v-model="vehicle.name"></md-input>
                                            <span class="md-error" v-if="!$v.vehicle.name.required">Base is required</span>
                                        </md-field>
                                        <md-field>
                                            <label>Description</label>
                                            <md-textarea v-model="vehicle.description" md-counter="250"></md-textarea>
                                        </md-field>
                                        <md-field :class="getValidationClass('base')">
                                            <md-select v-model="vehicle.base" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type">
                                                <md-option v-for="vehicleType in baseVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type ? vehicleType.type : vehicleType.name }}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.vehicle.base.required">Vehicle Type is required</span>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <CRow>
                                
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('passengers')">
                                            <md-icon>people</md-icon>
                                            <label>Passengers</label>
                                            <md-input v-model="vehicle.passengers" type="number" :max="selectedBase.passengers"></md-input>
                                            <span class="md-error" v-if="!$v.vehicle.passengers.required">Required</span>
                                            <span class="md-error" v-if="!$v.vehicle.passengers.between">Number must be between 1 and {{selectedBase.passengers}}</span>
                                        </md-field>
                                    </CCol>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('luggage')">
                                            <md-icon>luggage</md-icon>
                                            <label>Luggage</label>
                                            <md-input v-model="vehicle.luggage" type="number" :max="selectedBase.luggage"></md-input>
                                            <span class="md-error" v-if="!$v.vehicle.luggage.required">Required</span>
                                            <span class="md-error" v-if="!$v.vehicle.luggage.between">Number must be between 1 and {{selectedBase.luggage}}</span>
                                        </md-field>
                                    </CCol>
                                    
                                </CRow>
                                <md-divider class="mt-3"></md-divider>
                                <CRow >
                                    <CCol sm="12">
                                        <md-switch v-model="vehicle.visible" class="md-primary">Visible</md-switch>
                                    </CCol>
                                </CRow>
                                <CRow >
                                    <CCol sm="12">
                                        <md-button class="md-raised md-accent full-width-button " @click="fireDeleteDialog">Delete vehicle</md-button>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12">
                                        <md-field class="d-none">
                                            <label for="vehicle-pictures">Vehicle images</label>
                                            <md-file ref="carfile" multiple="multiple" name="vehicle-pictures" id="vehicle-pictures" :disabled="sending" @change="imageChanged($event, 'car-picture')"/>
                                        </md-field>
                                        
                                    </CCol>
                                </CRow>
                            </md-card-content>
                            
                        </md-card>
                    </CCol>
                    
                </CRow>
            </md-tab>
            <md-tab id="mainInfo" md-label="Main info" :md-template-data="{ badge: tabs.mainInfo.errors }">
                <CRow>
                    <CCol sm="12" md="6" lg="6" xl="4">
                        <md-card class="uplimo-card">
                            <md-card-header >
                                <div class="md-title">Main info</div>
                            </md-card-header>
                            <md-card-content >
                                <md-field :class="getValidationClass('mainInfo.plate')">
                                    <label for="plate">Plate</label>
                                    <md-input name="plate" id="plate" autocomplete="given-name" v-model="vehicle.mainInfo.plate" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.vehicle.mainInfo.plate.required">Plate is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('mainInfo.brand')">
                                    <label for="brand">Brand</label>
                                    <md-input name="brand" id="brand" autocomplete="given-name" v-model="vehicle.mainInfo.brand" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.vehicle.mainInfo.brand.required">Brand is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('mainInfo.carModel')">
                                    <label for="car-model">Car model</label>
                                    <md-input name="car-model" id="car-model" autocomplete="given-name" v-model="vehicle.mainInfo.carModel" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.vehicle.mainInfo.carModel.required">Car model is required</span>
                                </md-field>
                            

                                <md-field :class="getValidationClass('mainInfo.year')">
                                    <label for="year">Year</label>
                                    <md-input name="year" id="year" autocomplete="given-name" v-model="vehicle.mainInfo.year" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.vehicle.mainInfo.year.required">Year is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('mainInfo.color')">
                                    <label for="color">Color</label>
                                    <md-input name="color" id="color" autocomplete="given-name" v-model="vehicle.mainInfo.color" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.vehicle.mainInfo.color.required">Color is required</span>
                                </md-field>

                                
                            </md-card-content>
                        </md-card>
                    </CCol>
                </CRow>
            </md-tab>
            <md-tab id="documents" md-label="Documents" :md-template-data="{ badge: tabs.documents.errors }">
                <CRow>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <!--md-card-header >
                                    <div class="md-title">FH1</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                <div class="doc-asset">
                                    
                                    <div class="pdf-preview-holder" @click="openDocDialog('FH1', pictures.fh1.preview, pictures.fh1.image, pictures.fh1.pdf)" v-if="pictures.fh1.pdf && pictures.fh1.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="pictures.fh1.preview"    />
                                    </div>
                                    <div class="pdf-preview-holder" @click="openDocDialog('FH1', vehicle.documents.fh1.storage, vehicle.documents.fh1.image, vehicle.documents.fh1.pdf)" v-else-if="vehicle.documents.fh1.storage != null && !pictures.fh1.preview && !pictures.fh1.deleted">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="vehicle.documents.fh1.storage"    :loaded="pdfLoaded()"/>
                                    </div>
                                    <div class="img-preview-holder"  v-if="pictures.fh1.image || vehicle.documents.fh1.image">
                                        <div 
                                            class="doc-overlay"
                                            v-if="pictures.fh1.image && pictures.fh1.preview"
                                            @click="openDocDialog('fh1', pictures.fh1.preview, pictures.fh1.image, pictures.fh1.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <div 
                                            class="doc-overlay"
                                            v-else-if="vehicle.documents.fh1.image && vehicle.documents.fh1.storage != null && !pictures.fh1.preview"
                                            @click="openDocDialog('fh1', vehicle.documents.fh1.storage, vehicle.documents.fh1.image, vehicle.documents.fh1.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>

                                        <img :src="this.pictures.fh1.preview ? this.pictures.fh1.preview : ''" alt="" />
                                        <img :src="this.vehicle.documents.fh1.storage ? this.vehicle.documents.fh1.storage : ''" alt="" />

                                    </div>
                                    
                                </div>
                               
                                <div class="doc-data">
                                     <div class="md-title">FH1</div>
                                    <CRow>
                                        <CCol :sm="!vehicle.documents.fh1.fileName ? 12 : 10">
                                             <md-field :class="getValidationClass('documents.fileName','fh1')" >
                                                <label for="fh1-picture">FH1 attachment (Image or PDF)</label>
                                                <md-file name="fh1-picture" id="fh1-picture" v-model="vehicle.documents.fh1.fileName" :disabled="sending" @change="docImageChanged($event, 'fh1')" ref="fh1FileInput" />
                                                <span class="md-error" v-if="!$v.vehicle.documents.fh1.fileName.required">FH1 picture is required</span>
                                            </md-field>
                                        </CCol>
                                        <CCol sm="2" v-if="vehicle.documents.fh1.fileName">
                                            <md-button class="md-dense md-raised md-primary ml-0 full-width-button" @click="fireDocReplace('fh1',vehicle.documents.fh1)">Replace attachment</md-button>
                                        </CCol>
                                    </CRow>
                                    
                                    <md-datepicker :class="getValidationClass('documents.expiration','fh1')" name="fh1-expiration-date" id="fh1-expiration-date" v-model="vehicle.documents.fh1.expiration" :disabled="sending" :md-model-type="Number">
                                        <label for="fh1-expiration-date">FH1 Expiration Date</label>
                                        <span class="md-error" v-if="!$v.vehicle.documents.fh1.expiration.required">FH1 Expiration Date is required</span>
                                    </md-datepicker>
                                    <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.fh1.expiration)" v-if="vehicle.documents.fh1.expiration"></div>
                                    <div class="mt-3" v-if="!pictures.fh1.deleted">
                                        <md-divider class="mb-3"></md-divider>
                                        
                                        <md-button class="md-dense md-raised md-accent" @click="fireDocDelete('fh1',vehicle.documents.fh1)" v-if="!vehicle.documents.fh1.deleted && vehicle.documents.fh1.storage != null">Delete entire document</md-button>
                                    </div>

                                </div>
                                

                               
                                
                                
                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <!--md-card-header >
                                    <div class="md-title">Registration</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                <div class="doc-asset">
                                    
                                    <div class="pdf-preview-holder" @click="openDocDialog('registration', pictures.registration.preview, pictures.registration.image, pictures.registration.pdf)" v-if="pictures.registration.pdf && pictures.registration.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="pictures.registration.preview"    />
                                    </div>
                                    <div class="pdf-preview-holder" @click="openDocDialog('registration', vehicle.documents.registration.storage, vehicle.documents.registration.image, vehicle.documents.registration.pdf)" v-else-if="vehicle.documents.registration.storage != null && !pictures.registration.preview && !pictures.registration.deleted">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="vehicle.documents.registration.storage"    :loaded="pdfLoaded()"/>
                                    </div>
                                    
                                    <!--div class="pdf-preview-holder" @click="openDocDialog('registration', pictures.registration.preview, pictures.registration.image, pictures.registration.pdf)" v-if="pictures.registration.pdf">
                                        <vue-pdf-embed :page="1" :source="pictures.registration.preview"    />
                                    </div-->
                                     <div class="img-preview-holder" v-if="pictures.registration.image || vehicle.documents.registration.image">
                                        <div 
                                            class="doc-overlay"
                                            v-if="pictures.registration.image && pictures.registration.preview"
                                            @click="openDocDialog('registration', pictures.registration.preview, pictures.registration.image, pictures.registration.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <div 
                                            class="doc-overlay"
                                            v-else-if="vehicle.documents.registration.image && vehicle.documents.registration.storage != null && !pictures.registration.preview"
                                            @click="openDocDialog('registration', vehicle.documents.registration.storage, vehicle.documents.registration.image, vehicle.documents.registration.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <img :src="this.pictures.registration.preview ? this.pictures.registration.preview : ''" alt=""  />
                                        <img :src="this.vehicle.documents.registration.storage ? this.vehicle.documents.registration.storage : ''" alt="" />

                                    </div>
                                </div>
                                <div class="doc-data">
                                    <div class="md-title">Registration</div>
                                    <CRow>
                                        <CCol :sm="!vehicle.documents.registration.fileName ? 12 : 10">
                                            <md-field :class="getValidationClass('documents.fileName','registration')">
                                                <label for="registration-picture">Attachment (Image or PDF)</label>
                                                <md-file name="registration-picture" id="registration-picture" v-model="vehicle.documents.registration.fileName" :disabled="sending"  @change="docImageChanged($event, 'registration')" ref="registrationFileInput"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.registration.fileName.required">Registration picture is required</span>
                                            </md-field>
                                        </CCol>
                                        <CCol sm="2" v-if="vehicle.documents.registration.fileName">
                                            <md-button class="md-dense md-raised md-primary ml-0 full-width-button" @click="fireDocReplace('registration',vehicle.documents.registration)">Replace attachment</md-button>
                                        </CCol>
                                    </CRow>
                                    
                                    <md-datepicker :class="getValidationClass('documents.expiration','registration')" name="registration-expiration-date" id="registration-expiration-date" v-model="vehicle.documents.registration.expiration" :disabled="sending" :md-model-type="Number">
                                        <label for="registration-expiration-date">Expiration date</label>
                                        <span class="md-error" v-if="!$v.vehicle.documents.registration.expiration.required">Registration expiration date is required</span>
                                    </md-datepicker>
                                    <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.registration.expiration)" v-if="vehicle.documents.registration.expiration"></div>
                                     <div class="mt-3" v-if="!pictures.registration.deleted">
                                        <md-divider class="mb-3"></md-divider>
                                        
                                        <md-button class="md-dense md-raised md-accent" @click="fireDocDelete('registration',vehicle.documents.registration)" v-if="!vehicle.documents.registration.deleted && vehicle.documents.registration.storage != null">Delete entire document</md-button>
                                    </div>
                                </div>
                            
                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <!--md-card-header >
                                    <div class="md-title">TLC window sticker</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                    <div class="doc-asset">
                                        
                                        <div class="pdf-preview-holder" @click="openDocDialog('TLC window sticker', pictures.tlc.preview, pictures.tlc.image, pictures.tlc.pdf)" v-if="pictures.tlc.pdf && pictures.tlc.preview">
                                            <div class="doc-overlay">
                                                <md-icon class="md-size-2x">search</md-icon>
                                            </div>
                                            <vue-pdf-embed :page="1" :source="pictures.tlc.preview"    />
                                        </div>
                                        <div class="pdf-preview-holder" @click="openDocDialog('TLC window sticker', vehicle.documents.tlc.storage, vehicle.documents.tlc.image, vehicle.documents.tlc.pdf)" v-else-if="vehicle.documents.tlc.storage != null && !pictures.tlc.preview && !pictures.tlc.deleted">
                                            <div class="doc-overlay">
                                                <md-icon class="md-size-2x">search</md-icon>
                                            </div>
                                            <vue-pdf-embed :page="1" :source="vehicle.documents.tlc.storage"    :loaded="pdfLoaded()"/>
                                        </div>
                                         <div class="img-preview-holder" v-if="pictures.tlc.image || vehicle.documents.tlc.image">
                                            <div 
                                                class="doc-overlay"
                                                v-if="pictures.tlc.image && pictures.tlc.preview"
                                                @click="openDocDialog('tlc', pictures.tlc.preview, pictures.tlc.image, pictures.tlc.pdf)"
                                            >
                                                <md-icon class="md-size-2x">search</md-icon>
                                            </div>
                                            <div 
                                                class="doc-overlay"
                                                v-else-if="vehicle.documents.tlc.image && vehicle.documents.tlc.storage != null && !pictures.tlc.preview"
                                                @click="openDocDialog('tlc', vehicle.documents.tlc.storage, vehicle.documents.tlc.image, vehicle.documents.tlc.pdf)"
                                            >
                                                <md-icon class="md-size-2x">search</md-icon>
                                            </div>
                                            <img :src="this.pictures.tlc.preview ? this.pictures.tlc.preview : ''" alt=""  />
                                            <img :src="this.vehicle.documents.tlc.storage ? this.vehicle.documents.tlc.storage : ''" alt=""  />

                                        </div>
                                    </div>
                                    <div class="doc-data">
                                         <div class="md-title">TLC window sticker</div>
                                         <CRow>
                                            <CCol :sm="!vehicle.documents.tlc.fileName ? 12 : 10">
                                                 <md-field :class="getValidationClass('documents.fileName','tlc')">
                                                    <label for="tlc-window-sticker-picture">Attachment (Image or PDF)</label>
                                                    <md-file name="tlc-window-sticker-picture" id="tlc-window-sticker-picture" v-model="vehicle.documents.tlc.fileName" :disabled="sending"  @change="docImageChanged($event, 'tlc', pictures.tlc.image, pictures.tlc.pdf)" ref="tlcFileInput"/>
                                                    <span class="md-error" v-if="!$v.vehicle.documents.tlc.fileName.required">picture is required</span>
                                                </md-field>
                                            </CCol>
                                            <CCol sm="2" v-if="vehicle.documents.tlc.fileName">
                                                <md-button class="md-dense md-raised md-primary ml-0 full-width-button" @click="fireDocReplace('tlc',vehicle.documents.tlc)">Replace attachment</md-button>
                                            </CCol>
                                        </CRow>
                                       
                                        <md-datepicker :class="getValidationClass('documents.expiration','tlc')" name="tlc-window-sticker-expiration" id="tlc-window-sticker-expiration" v-model="vehicle.documents.tlc.expiration" :disabled="sending" :md-model-type="Number">
                                            <label for="tlc-window-sticker-expiration">Expiration</label>
                                            <span class="md-error" v-if="!$v.vehicle.documents.tlc.expiration.required">expiration is required</span>
                                        </md-datepicker>
                                        <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.tlc.expiration)" v-if="vehicle.documents.tlc.expiration"></div>
                                        <div class="mt-3" v-if="!pictures.tlc.deleted">
                                            <md-divider class="mb-3"></md-divider>
                                            
                                            <md-button class="md-dense md-raised md-accent" @click="fireDocDelete('tlc',vehicle.documents.tlc)" v-if="!vehicle.documents.tlc.deleted && vehicle.documents.tlc.storage != null">Delete entire document</md-button>
                                        </div>
                                    </div>
                            
                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <!--md-card-header >
                                    <div class="md-title">DMV inspection sticker</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                <div class="doc-asset">
                                    
                                    <div class="pdf-preview-holder" @click="openDocDialog('DMV inspection sticker', pictures.dmv.preview, pictures.dmv.image, pictures.dmv.pdf)" v-if="pictures.dmv.pdf && pictures.dmv.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="pictures.dmv.preview"    />
                                    </div>
                                    <div class="pdf-preview-holder" @click="openDocDialog('DMV inspection sticker', vehicle.documents.dmv.storage, vehicle.documents.dmv.image, vehicle.documents.dmv.pdf)" v-else-if="vehicle.documents.dmv.storage != null && !pictures.dmv.preview && !pictures.dmv.deleted">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="vehicle.documents.dmv.storage"    :loaded="pdfLoaded()"/>
                                    </div>
                                     <div class="img-preview-holder" v-if="pictures.dmv.image || vehicle.documents.dmv.image">
                                        <div 
                                            class="doc-overlay"
                                            v-if="pictures.dmv.image && pictures.dmv.preview"
                                            @click="openDocDialog('DMV inspection sticker', pictures.dmv.preview, pictures.dmv.image, pictures.dmv.pdf)" 
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <div 
                                            class="doc-overlay"
                                            v-else-if="vehicle.documents.dmv.image && vehicle.documents.dmv.storage != null && !pictures.dmv.preview"
                                            @click="openDocDialog('DMV inspection sticker', vehicle.documents.dmv.storage, vehicle.documents.dmv.image, vehicle.documents.dmv.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <img :src="this.pictures.dmv.preview ? this.pictures.dmv.preview : ''" alt="" />
                                         <img :src="this.vehicle.documents.dmv.storage ? this.vehicle.documents.dmv.storage : ''" alt=""  />

                                    </div>
                                </div>
                                <div class="doc-data">
                                     <div class="md-title">DMV inspection sticker</div>
                                     <CRow>
                                        <CCol :sm="!vehicle.documents.dmv.fileName ? 12 : 10">
                                            <md-field :class="getValidationClass('documents.fileName','dmv')">
                                                <label for="dmv-inspection-sticker-picture">Attachment (Image or PDF)</label>
                                                <md-file name="dmv-inspection-sticker-picture" id="dmv-inspection-sticker-picture" v-model="vehicle.documents.dmv.fileName" :disabled="sending" @change="docImageChanged($event, 'dmv', pictures.dmv.image, pictures.dmv.pdf)" ref="dmvFileInput"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.dmv.fileName.required">picture is required</span>
                                            </md-field>
                                        </CCol>
                                        <CCol sm="2" v-if="vehicle.documents.dmv.fileName">
                                            <md-button class="md-dense md-raised md-primary ml-0 full-width-button" @click="fireDocReplace('dmv',vehicle.documents.dmv)">Replace attachment</md-button>
                                        </CCol>
                                    </CRow>
                                    
                                    <md-datepicker :class="getValidationClass('documents.expiration','dmv')" name="dmv-inspection-sticker-expiration" id="dmv-inspection-sticker-expiration" v-model="vehicle.documents.dmv.expiration" :disabled="sending" :md-model-type="Number">
                                        <label for="dmv-inspection-sticker-expiration">Expiration</label>
                                        <span class="md-error" v-if="!$v.vehicle.documents.dmv.expiration.required">expiration is required</span>
                                    </md-datepicker>
                                    <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.dmv.expiration)" v-if="vehicle.documents.dmv.expiration"></div>
                                    <div class="mt-3" v-if="!pictures.dmv.deleted">
                                        <md-divider class="mb-3"></md-divider>
                                        
                                        <md-button class="md-dense md-raised md-accent" @click="fireDocDelete('dmv',vehicle.documents.dmv)" v-if="!vehicle.documents.dmv.deleted && vehicle.documents.dmv.storage != null">Delete entire document</md-button>
                                    </div>
                                </div>
                                

                            </md-card-content>
                        </md-card>
                    </CCol>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <!--md-card-header >
                                    <div class="md-title">Liability insurance with driver listed</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                <div class="doc-asset">
                                    
                                    <div class="pdf-preview-holder" @click="openDocDialog('Liability insurance with driver listed', pictures.liability.preview, pictures.liability.image, pictures.liability.pdf)" v-if="pictures.liability.pdf && pictures.liability.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="pictures.liability.preview"    />
                                    </div>
                                    <div class="pdf-preview-holder" @click="openDocDialog('Liability insurance with driver listed', vehicle.documents.liability.storage, vehicle.documents.liability.image, vehicle.documents.liability.pdf)" v-else-if="vehicle.documents.liability.storage != null && !pictures.liability.preview && !pictures.liability.deleted">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="vehicle.documents.liability.storage"    :loaded="pdfLoaded()"/>
                                    </div>

                                     <div class="img-preview-holder" v-if="pictures.liability.image || vehicle.documents.liability.image">
                                        <div 
                                            class="doc-overlay"
                                            v-if="pictures.liability.image && pictures.liability.preview"
                                            @click="openDocDialog('Liability insurance with driver listed', pictures.liability.preview, pictures.liability.image, pictures.liability.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <div 
                                            class="doc-overlay"
                                            v-else-if="vehicle.documents.liability.image && vehicle.documents.liability.storage != null && !pictures.liability.preview"
                                            @click="openDocDialog('Liability insurance with driver listed', vehicle.documents.liability.storage, vehicle.documents.liability.image, vehicle.documents.liability.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <img :src="this.pictures.liability.preview ? this.pictures.liability.preview : ''" alt=""  />
                                        <img :src="this.vehicle.documents.liability.storage ? this.vehicle.documents.liability.storage : ''" alt=""  />

                                    </div>
                                </div>
                                <div class="doc-data">
                                     <div class="md-title">Liability insurance with driver listed</div>
                                     <CRow>
                                        <CCol :sm="!vehicle.documents.liability.fileName ? 12 : 10">
                                            <md-field :class="getValidationClass('documents.fileName','liability')">
                                                <label for="liability-insurance-with-driver-listed-picture">Attachment (Image or PDF)</label>
                                                <md-file name="liability-insurance-with-driver-listed-picture" id="liability-insurance-with-driver-listed-picture" v-model="vehicle.documents.liability.fileName" :disabled="sending" @change="docImageChanged($event, 'liability', pictures.liability.image, pictures.liability.pdf)" ref="liabilityFileInput"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.liability.fileName.required">Liability insurance with driver listed picture is required</span>
                                            </md-field>
                                        </CCol>
                                        <CCol sm="2" v-if="vehicle.documents.liability.fileName">
                                            <md-button class="md-dense md-raised md-primary ml-0 full-width-button" @click="fireDocReplace('liability',vehicle.documents.liability)">Replace attachment</md-button>
                                        </CCol>
                                    </CRow>
                                    <div class="mt-3" v-if="!pictures.liability.deleted">
                                        <md-divider class="mb-3"></md-divider>
                                        
                                        <md-button class="md-dense md-raised md-accent" @click="fireDocDelete('liability',vehicle.documents.liability)" v-if="!vehicle.documents.liability.deleted && vehicle.documents.liability.storage != null">Delete entire document</md-button>
                                    </div>
                                    
                                </div>
                            </md-card-content>
                        </md-card>
                    </CCol>         
                </CRow>
            </md-tab>
            <md-tab id="pictures" md-label="Pictures" :md-template-data="{ badge: tabs.pictures.errors }">
                <CRow >
                    <CCol sm="12" lg="4" v-for="(picture,index) in vehicle.pictures" v-bind:key="picture.id" >
                        <md-card>
                            <md-card-content>
                                <img :src="picture" alt="">
                                <md-card-actions>
                                    <md-button class="md-raised" @click="removePicture(picture, false,index)">Remove image</md-button>
                                </md-card-actions>
                                
                            </md-card-content>
                        </md-card>
                        
                    </CCol>
                    <CCol sm="12" lg="4" v-for="(newPicture,index) in files" v-bind:key="newPicture.id">
                        <md-card>
                            <md-card-content class="preview-card-content">
                                <p>Framing result (public image)</p>
                                 <div ref="cropperPreviewContainer" class="cropper-container mb-4">
                                    <div :class="'cropper-preview-' + index" ref="cropperPreview"></div>
                                </div>
                                <div v-if="newPicture.editing">
                                    <md-divider class="mb-2"></md-divider> 
                                    <p class="md-body">Custom image framing (zoom out, zoom in, move , crop)</p>   
                                </div>
                                 <md-button v-if="!newPicture.editing" @click="enableCropper(newPicture)" class="full-width-button mt-2 md-raised">edit framing</md-button>
                                <md-button v-else @click="disableCropper(newPicture)" class="full-width-button mt-2 md-raised md-primary">confirm framing</md-button>
                                <div class="d-flex justify-content-start buttons-container mt-3" v-if="newPicture.editing">
                                    
                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="zoomIn(index)"><md-icon>zoom_in</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="zoomOut(index)"><md-icon>zoom_out</md-icon></md-button>
                                    </div>
                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="flip(index)"><md-icon>flip</md-icon></md-button>
                                    </div>

                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="move(-10,0,index)"><md-icon>arrow_back</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(10,0,index)"><md-icon>arrow_forward</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(0,-10,index)"><md-icon>arrow_upward</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(0,10,index)"><md-icon>arrow_downward</md-icon></md-button>
                                    </div>
                                    <md-button class="md-raised md-dense position-relative" :class="{ 'remove-button': files.length > 1 }" @click="removePicture(newPicture,true,index)"><md-icon class="mr-2">delete</md-icon>Remove image <b>#{{activeIndex + 1}}</b></md-button>
                                </div>
                               
                                <vue-cropper 
                                    :zoomOnWheel="false"          
                                    :src="newPicture.imgSrc"
                                    :aspect-ratio="16 / 9"     
                                    ref="cropper" 
                                    :preview="'.cropper-preview-' + index"
                                    v-show="newPicture.cropper"
                                    :minContainerHeight="cropper.height"
                                    :minContainerWidth="cropper.width"
                                    >
                                    
                                </vue-cropper>
                               
                                
                                    
                                
                                
                                
                            </md-card-content>
                        </md-card>
                        
                    </CCol>
                    <CCol sm="12" lg="4">
                        <md-card class="uplimo-card">
                            <md-card-header >
                            </md-card-header>
                            <md-card-content >
                                <md-empty-state
                        
                                    
                                    md-icon="image"
                                    md-label="Add new"
                                    md-description="Add vehicle image/s.">
                                    <md-button class="md-raised md-dense md-primary" @click="addPicture">Add image</md-button>
                                </md-empty-state>
                            </md-card-content>
                        </md-card>
                    </CCol>
                    
                </CRow>
                
            </md-tab>
            <md-tab id="otherLicenses" md-label="Other Licenses" :md-template-data="{ badge: tabs.otherLicenses.errors }">
                <md-empty-state
                    v-if="otherLicenses.length === 0"
                    
                    md-icon="badge"
                    md-label="Custom licenses and permissions"
                    md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                    <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                </md-empty-state>
                <CRow v-else>
                    <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in otherLicenses" v-bind:key="'otherLicenses-' + index ">
                        <md-card class="uplimo-card" >
                            <!--md-card-header >
                                    <div class="md-title">FH1</div>
                            </md-card-header-->
                            <md-card-content class="listed-doc-card">
                                <div class="doc-asset">

                                    <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                    </div>
                                    <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                        <div class="doc-overlay">
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                    </div>

                                   
                                    <div class="img-preview-holder"  v-if="license.attachment.image">
                                        <div 
                                            class="doc-overlay"
                                            v-if="license.attachment.image && license.attachment.preview"
                                            @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>
                                        <div 
                                            class="doc-overlay"
                                            v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                            @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                        >
                                            <md-icon class="md-size-2x">search</md-icon>
                                        </div>

                                        <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                        <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />

                                    </div>
                                    
                                </div>
                               
                                <div class="doc-data">
                                     <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('otherLicenses.name',index)">
                                                <label>Name</label>
                                                <md-input v-model="license.name"></md-input>
                                                <span class="md-error" v-if="!$v.otherLicenses.$each[index].name.required">Name is required</span>
                                            </md-field>
                                             <md-field :class="getValidationClass('otherLicenses.file',index)">
                                                <label >Attachment (Image or PDF)</label>
                                                <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                <span class="md-error" v-if="!$v.otherLicenses.$each[index].attachment.file.required">Attachment is required</span>
                                            </md-field>
                                            <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" :class="getValidationClass('otherLicenses.expiration',index)">
                                                <label>Expiration Date</label>
                                                <span class="md-error" v-if="!$v.otherLicenses.$each[index].expiration.required">Expiration Date is required</span>
                                            </md-datepicker>
                                            <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                            <md-button class="md-raised md-dense full-width-button" @click="removeLicense(index)">Remove license</md-button>
                                        </CCol>
                                        
                                    </CRow>
                                    
                                    

                                </div>  
                            </md-card-content>
                        </md-card>
                        
                    </CCol>
                    <CCol sm="12" md="12" lg="12" xl="12">
                        <md-card class="uplimo-card">
                            <md-card-header >
                            </md-card-header>
                            <md-card-content >
                                <md-empty-state
                        
                                    
                                    md-icon="badge"
                                    md-label="Add new"
                                    md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                                    <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                                </md-empty-state>
                            </md-card-content>
                        </md-card>
                    </CCol>

                </CRow>
                
                
            </md-tab>
        </md-tabs>
        
        <transition name="fade">
            <div class="mobile-backdrop-overlay" v-show="showSelector && dialogWidth < 992" @click="showSelector = false"></div>
        </transition>
        <transition name="fade">
            <md-toolbar class="md-dense bottom-fixed extra-case md-elevation-5" v-show="showSelector && dialogWidth < 992" :style="[dialogDimensions]">
                <div class="md-toolbar-row">
                    <md-list>
                        <md-list-item 
                            :class="{ 'active-item-tab' : activeTabIndex == index }" 
                            v-for="(step,index) in steps" 
                            v-bind:key="'selector-mobile' + step.id" 
                            @click="changeMobileTab(step.id, index)">
                            
                            <span class="md-list-item-text">{{ step.label }}</span>
                            
                            <md-badge class="md-accent" :md-content="tabs[step.tabErrorLabel].errors" v-if="tabs[step.tabErrorLabel].errors" />
                            <md-badge class="confirmed" md-content="OK" v-if="tabs[step.tabErrorLabel].errors == 0" />
                        </md-list-item>
                    </md-list>
                </div>
            </md-toolbar>
        </transition>
        <transition name="fade">
            <md-toolbar class="md-dense bottom-fixed" v-show="dialogWidth < 992" :style="[dialogDimensions]">
                
                <div class="md-toolbar-row">
                    <h3 class="md-title flex-1">{{ activeTabLabel }}</h3>
                    <md-button class="md-icon-button" @click="prevTab()" :disabled="activeTabIndex == 0">
                        <md-icon>arrow_back_ios</md-icon>
                    </md-button>
                    <md-button class="md-icon-button" @click="nextTab()" :disabled="activeTabIndex + 1 == this.steps.length">
                        <md-icon>arrow_forward_ios</md-icon>
                    </md-button>
                    <md-button class="md-icon-button" @click="showSelector = !showSelector" >
                        <md-icon>more_vert</md-icon>
                    </md-button>
                </div>
            </md-toolbar>
        </transition>

         <md-dialog :md-active.sync="docDialog.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog" >
            <md-dialog-title v-if="docDialog.name">
                {{ docDialog.name}}
                
            </md-dialog-title>
            
            <div class="modal-up-content text-center">
                <img :src="docDialog.src" alt="" v-if="docDialog.image">


                <md-button :disabled="docDialog.page == 1" @click="prevPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Prev</md-button>
                <md-button :disabled="docDialog.page == docDialog.pageCount" @click="nextPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Next</md-button>

                <div class="popup-pdf-holder" v-if="docDialog.pdf">
                    <vue-pdf-embed :source="docDialog.src"   :page="docDialog.page" ref="masterPdf" :width="pdfWidth" />
                </div>
                
            </div>
            <md-dialog-actions>
                <md-button @click="docDialog.active = false">Close</md-button>
            
            </md-dialog-actions>
        </md-dialog>
        <md-dialog
            :style="[dialogDimensions]"
            :md-active.sync="confirmDialog"
        >
            <md-dialog-title>What would you like to do ?</md-dialog-title>
            <md-dialog-content>
                <p>You made changes in this vehicle. Choose your action </p>
                <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save &amp; exit</md-button>
            </md-dialog-content>

            
            
        </md-dialog>
        <md-dialog :md-active.sync="deleteDialog.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog">
     
            <md-dialog-title>
                {{deleteDialog.title}}
            </md-dialog-title>
            <md-divider class=" mb-4">
            </md-divider>
            <div class="modal-up-content text-center" v-if="deleteDialog.busy">
                <md-progress-spinner class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" ></md-progress-spinner>
            </div>
            
            <div class="modal-up-content" v-if="!deleteDialog.busy">
                
                <div class="md-display-1 mt-3" :class="{'up-text-confirmed' : deleteDialog.unlocked , 'up-text-cancelled': !deleteDialog.unlocked}">  
                <md-icon  class="md-icon-40" :class="{'up-text-confirmed' : deleteDialog.unlocked , 'up-text-cancelled': !deleteDialog.unlocked}">{{ deleteDialog.unlocked ? 'lock_open' : 'lock_outline' }}</md-icon>
                {{ deleteDialog.unlockCode}}
                </div>
                <md-field>
                <label>Type above code to unlock </label>
                <md-input v-model="deleteDialog.unlockTry" @keyup="checkLock"></md-input>
                </md-field>
            </div>
            <md-dialog-actions v-if="!deleteDialog.busy">
                <md-button @click="deleteDialog.active = false">Close</md-button>
                <md-button class="md-accent md-raised" @click="destroy" :disabled="!deleteDialog.unlocked">Delete vehicle</md-button>
            </md-dialog-actions>
        </md-dialog>
        
         <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
            <span>{{snackMessage}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
        </md-snackbar>
    </div>
    </div>
</template>

<script>
import firebase from '../../Firebase'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    between,
    maxLength,
    requiredIf
  } from 'vuelidate/lib/validators'

import { ContentLoader } from 'vue-content-loader'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import moment from 'moment'
import moment_timezone from "moment-timezone";
import axiosHelper from "./../../helpers/axios"

export default {
  name: 'VehicleType',
  components: {
     ContentLoader,
     VueCropper,
     VuePdfEmbed
  },
  mixins: [validationMixin],
  
  data() {
    return {
        activeTabIndex: 0,
        activeTabLabel: 'General',
        steps: [    
      

            { id: 'general', label: 'General' , tabErrorLabel: 'general'} ,
            { id: 'mainInfo', label: 'Main info' , tabErrorLabel: 'mainInfo'} ,
            { id: 'documents', label: 'Documents' , tabErrorLabel: 'documents'} ,
            { id: 'pictures', label: 'Pictures' , tabErrorLabel: 'pictures'} ,
            { id: 'otherLicenses', label: ' Other Licenses' , tabErrorLabel: 'otherLicenses'} ,
            
        ] ,
        showSelector: false,
        deleteDialog: {
            title: 'Are you sure you want to delete this vehicle?',
            available: false,
            active:false,
            error: {
                title: null,
                message: null
            },
            confirmed: false,
        
            
            unlockCode: null,
            unlockTry: null,
            unlocked: false,
            busy: false

      },
      isEditing: false,
      confirmDialog: false,
      goTo: null,
      confirmLeave: false,
      dialogLeft: 0,
      docDialog: {
        name: null,
        active: false,
        src: null,
        pdf: false,
        image: false,
        pageCount: null,
        page: 1

      },
      cropper: {
        width: 250,
        height:150,
      },
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      activeIndex: 0,
      sending: false,
      files: [],
      removedPictures:[],
      removedLicenses: [],
      baseVehicleTypes: [],
      vehicle: null,
      isBusy: true,
      selectedBase: {
        passengers: null,
        luggage: null
      },
      tabs: {
        general: {
            errors: null,
        },
        mainInfo: {
            errors: null,
        },
        documents: {
            errors: null,
        },
        pictures: {
            errors: null,
        },
        otherLicenses: {
            errors: null
        }
        
        
       },
        mainActiveTab: 'general',
        //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types')
    
        pictures: {
            fh1: {
                preview: null,
                file:null,
                pdf: false,
                image: false
            },
            registration: {
                preview: null,
                file:null,
                pdf: false,
                image: false
            },
            tlc: {
                preview: null,
                file:null,
                pdf: false,
                image: false
            },
            dmv:{
                preview: null,
                file:null,
                pdf: false,
                image: false
            },
            liability:{
                preview: null,
                file:null,
                pdf: false,
                image: false
            }
        },
        otherLicenses: []
    
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    headerHeight() {
      return this.$store.state.headerHeight;
    },
    dialogDimensions () {
        console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    widthMenu () {
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            width: (window.innerWidth - this.dialogLeft) + 'px'
        }
    },
    pdfWidth () {
        return  (window.innerWidth - this.dialogLeft) * 0.9 
        
    },
    dialogWidth() {
        return this.$store.state.dialogWidth
    },

    dialogHeight() {
        
      return {
        height: window.innerHeight - this.headerHeight + 'px',
        top: this.headerHeight + 'px'
        //height: this.$store.state.sidebarNavHeight + 'px'
      }
    },
    computedOtherLicenses: function() {
      return this.otherLicenses.map(function(i) {
        return i
      });
    }
      
  },
  watch: {
    'vehicleType.base': function(id){
        console.log(id)
        for (let i = 0; i < this.baseVehicleTypes.length; i++) {
            if(id == this.baseVehicleTypes[i].id) {
                this.selectedBase.passengers = this.baseVehicleTypes[i].passengers;
                this.selectedBase.luggage = this.baseVehicleTypes[i].luggage;
            }
            
        }
    },
    '$store.state.sidebarMinimize': function() {
        this.$store.commit('centerDialog',window.innerWidth)
    },
    '$store.state.sidebarShow': function(){
        this.$store.commit('centerDialog',window.innerWidth)
    },
    'vehicle': {
        handler(val,oldVal) {
            console.log('val',val)
            console.log('oldVal',oldVal)
            if(oldVal != null) {
                console.log('vehicle watch')
                //this.isEditing = true;
            }
        },
        deep: true

    },
  },
  beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
  },
  beforeRouteLeave(to, from, next) {

    if (this.isEditing && !this.confirmLeave) {
        this.confirmDialog = true;
        console.log(to);
        this.goTo = to;
        return 
        if (!window.confirm("Leave without saving?")) {
            return;
        }
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler);
},
  created () {
    let th = this;
    
    this.obtainViewResources()
    window.addEventListener('resize', this.myEventHandler);
    // firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
    //     this.baseVehicleTypes = [];
    //     querySnapshot.forEach((doc) => {
            
    //         let dataToSave = doc.data();
    //         dataToSave.id = doc.id ;
    //         this.baseVehicleTypes.push(dataToSave);
            
    //     });
    // });

    


  },
  methods: {
    changeMobileTab(id,index) {
        this.$refs.mainTabs.activeTab = id;
        this.activeTabLabel = this.steps[index].label
        this.activeTabIndex = index
        this.showSelector = false

    },
    prevTab() {
        let prevTabId = null ;
        let activeTabIndex = null;

        for (let i = 0; i < this.steps.length; i++) {
            if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                activeTabIndex = i
            }
            
        }
        console.log(activeTabIndex)
        prevTabId = this.steps[activeTabIndex - 1].id
        this.activeTabLabel = this.steps[activeTabIndex - 1].label
        this.activeTabIndex = activeTabIndex - 1
        this.$refs.mainTabs.activeTab = prevTabId;
    },
    nextTab() {
        let nextTabId = null ;
        let activeTabIndex = null;

        for (let i = 0; i < this.steps.length; i++) {
            if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                activeTabIndex = i
            }
            
        }
        console.log(activeTabIndex)
        nextTabId = this.steps[activeTabIndex + 1].id
        this.activeTabLabel = this.steps[activeTabIndex + 1].label
        this.activeTabIndex = activeTabIndex + 1
        
        this.$refs.mainTabs.activeTab = nextTabId;

    },
    async flushUserUpdates() {
        let id = this.user.data.uid
        let data = {

        }
        return new Promise((resolve, reject) => {
            //axios.post('users/:id/flush-update',data).then(response =>{
            axiosHelper.patch(`users/${id}/flush-update`,data).then(response =>{
                console.log(response);
                
                resolve()
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                //console.log(e);
                
                reject(e)
                
            }) 
        })
    },
    userStorageFolder(user) {
        
        return `users/`+ user.data.email +`/fleet/${this.$route.params.id}`;

    },
    getDocPath (user,hash) {
       

        return firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet').doc(hash)
    },
    getCollectionPath (user) {
        

        return firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types')
    },
    checkLock() {
      this.deleteDialog.unlockCode === this.deleteDialog.unlockTry ? this.deleteDialog.unlocked = true : this.deleteDialog.unlocked = false
    },
    fireDeleteDialog() {
        this.deleteDialog.active = true
    },
    async removeEntireVehicleFolder() {
        let email = this.user.data.email ;
        let urlList = [];
        for (const doc in this.vehicle.documents) {
            if (Object.hasOwnProperty.call(this.pictures, doc)) {
                const element = this.pictures[doc];
                
                let url = this.vehicle.documents[doc].storage;
                console.log(url)
                if(url) {
                    //await this.removeFileFromStorage(url)
                    urlList.push(url)
                }
                
            }
                
        }
        for (let i = 0; i < this.vehicle.otherLicenses.length; i++) {
            let url = this.otherLicenses[i].attachment.storage;
            if(url) {
                //await this.removeFileFromStorage(url)
                urlList.push(url)
            }
        }

        for (let i = 0; i < this.vehicle.pictures.length; i++) {
            let url = this.vehicle.pictures[i];
            if(url) {
                //await this.removeFileFromStorage(url)
                urlList.push(url)
            }
        }

        console.log(urlList)


        /////
        const promises = urlList.map((file, index) => {
            console.log(file);
            let fileRef = this.$firebase.storage().refFromURL(file);
            return fileRef.delete()
              .then(() => {
                console.log('deleted successfully');
              })
              .catch((err) => {
                console.log(err);
              });
        })
        return Promise.all(promises)
        .then(() => {
            console.log('Files deleted');
            
        })
        .catch((err) => alert(err.code));
        /////


        // await bucket.deleteFiles({
        //     prefix: `${folderName}/`
        // });
    },
    preventNav(event) {
        console.log(event)
        if (!this.isEditing) return
        event.preventDefault()
        event.returnValue = ""
    },
    async saveAndExit() {
        // this.isBusy = true;
        // if(this.validateAllSteps()){
        //     await this.update()
        //     this.onConfirm()
        // }
        // this.isBusy = false;
       
        this.fireUpdate()
    },
    onCancel() {
        this.confirmDialog = false
    },
    onConfirm() {
        this.confirmLeave = true
        this.$router.push({path: this.goTo.path})
    },
    fireDocDelete(type,doc) {
        // vehicle.documents.fh1.fileName
        // vehicle.documents.fh1.expiration
        
        doc.expiration = null
        doc.fileName = null
        doc.pdf = null
        doc.image = null
        
       

        this.pictures[type].preview = null 
        this.pictures[type].file = null
        this.pictures[type].pdf = null
        this.pictures[type].image = null
        this.pictures[type].changed = null
        this.pictures[type].deleted = true

    },
    fireDocReplace(type,doc) {
        
        let ref = type + 'FileInput' ;
        this.$refs[ref].$refs.inputFile.click()
    },
    enableCropper(picture){
        // let width = this.$refs.cropperPreviewContainer[0].offsetWidth
        // this.cropper.width = width
        // this.cropper.height = width * ( 9 / 16) 
        picture.cropper = true
        picture.editing = true
        // this.$nextTick(()=>{
        //     let width = this.$refs.cropperPreviewContainer[0].offsetWidth
        //     this.cropper.width = width
        //     this.cropper.height = width * ( 9 / 16) 
        // })
        
    },
    disableCropper(picture){
        picture.cropper = false
        picture.editing = false
    },
    pdfLoaded(call){
        //console.log(call)
    },
    async obtainViewResources(){
        this.isBusy = true
        await this.getVehicleTypes()
        await this.getVehicle()
        await this.imagesLoaded()
        this.isBusy = false
        

    },
    async getVehicle() {
        let th = this;
        let doc = this.getDocPath(this.user,this.$route.params.id)
        return new Promise((resolve, reject) => {
            
            
            doc.get()
            .then(function(doc) {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    th.vehicle = doc.data();
                    th.otherLicenses = th.vehicle.otherLicenses
                    th.deleteDialog.unlockCode = th.vehicle.name
                    for (let i = 0; i < th.baseVehicleTypes.length; i++) {
                        if(th.vehicle.base == th.baseVehicleTypes[i].id) {
                            th.selectedBase.passengers = th.baseVehicleTypes[i].passengers;
                            th.selectedBase.luggage = th.baseVehicleTypes[i].luggage;
                        }
                        
                    }
                    
                    //th.isBusy = false
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
                resolve()
            }).catch(function(error) {
                console.log("Error getting document:", error);
                reject()
            });

        }) 
    },
    async getVehicleTypes() {
        let th = this;
        let collection 

        if(this.user.data.activeRole == 'driver') {
            collection = this.$firebase.firestore().collection('vehicle-types')
        } else if (this.user.data.activeRole == 'company-admin') {
            collection = this.getCollectionPath(this.user)
        } else {
            collection = this.$firebase.firestore().collection('vehicle-types')
        }
        
        return new Promise((resolve, reject) => {

            collection.orderBy("order").onSnapshot((querySnapshot) => {
            //this.$firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                this.baseVehicleTypes = [];
                querySnapshot.forEach((doc) => {
                    
                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    this.baseVehicleTypes.push(dataToSave);
                    
                });
                resolve()
            });
        })  
        // for (let i = 0; i < this.baseVehicleTypes.length; i++) {
        //     if(id == this.baseVehicleTypes[i].id) {
        //         this.selectedBase.passengers = this.baseVehicleTypes[i].passengers;
        //         this.selectedBase.luggage = this.baseVehicleTypes[i].luggage;
        //     }
            
        // }
        
    },
    createLicense(){
        let license = {
            name: null,
            expiration: null,
            attachment: {
                preview: null,
                file:null,
                name: null,
                pdf: false,
                image: false
            }
        }

        this.otherLicenses.push(license)
    },
    removeLicense(index) {
        if(this.otherLicenses[index].attachment.storage) {
            this.otherLicenses[index].changed = null
            this.otherLicenses[index].deleted = true
            this.removedLicenses.push(this.otherLicenses[index])
            this.otherLicenses.splice(index, 1);
        } else {
            this.otherLicenses.splice(index, 1);
        }
            
           
    },
    zoomIn(index){
        this.$refs.cropper[index].relativeZoom(0.1);
        
    },
    zoomOut(index){
        this.$refs.cropper[index].relativeZoom(-0.1);
    },
    flip(index){
        
        const dom =  this.$refs.cropper[index].cropper.canvas;
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper[index].scaleX(scale);
        dom.setAttribute('data-scale', scale);
    },
    move(offsetX, offsetY,index) {
        this.$refs.cropper[index].move(offsetX, offsetY);
    },

    imagesLoaded(){
        const images = this.vehicle.pictures.map(imageSrc => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageSrc;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        // var assetBox = [];
        // for (const doc in this.vehicle.documents) {
        //     if (Object.hasOwnProperty.call(this.vehicle.documents, doc)) {
        //         const element = this.vehicle.documents[doc];
        //         if(element.storage) {
        //             assetBox.push(
        //                 new Promise((resolve, reject) => {
                            
        //                     const resource = new Image()
        //                     resource.src = element.storage
        //                     resource.onload = resolve;
        //                     resource.onerror = reject;
        //                 })
        //             )
        //         }
        //     }
        // }
        // console.log('assetBox',assetBox)

        Promise.all(images).then(() => { 
            this.$nextTick(() => {
                //this.isBusy = false;
            })
            
        }).catch(error => {
            console.error("Some image(s) failed loading!");
            console.error(error.message)
           
        });
    },
    carouselChanged(index){
        console.log(this.$refs.carousel);
        this.activeIndex = index;
    },
    async removeFileFromStorage(url) {
        let th = this
        return new Promise((resolve, reject) => {
            let pictureRef = this.$firebase.storage().refFromURL(url);
            pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
                resolve()
              })
              .catch((err) => {
                console.log(err);
                reject()
              });
        })
    },
    async uploadDocAttachment(destination, file) {
        let email = this.user.data.email ;
        return new Promise((resolve, reject) => {
            let ref = this.$firebase.storage().ref()
                            .child(destination)
            ref.put(file)
            .then(() => {
                
                let url = ref.getDownloadURL()
                resolve(url)
              })
              .catch((err) => {
                console.log(err);
                reject()
              });
           
        })
        
         

       
    },
    async updateAttachments() {
      let email = this.user.data.email ;
        for (const doc in this.pictures) {
          if (Object.hasOwnProperty.call(this.pictures, doc)) {
              const element = this.pictures[doc];
            
              console.log(element)
              //element.changed
              if(element.changed) {
                  let url = this.vehicle.documents[doc].storage;
                  console.log(url)
                  if(url) {
                      await this.removeFileFromStorage(url)
                  }
                  let path = this.userStorageFolder(this.user)
                  //let destination = `users/`+ email +`/fleet/${this.$route.params.id}/documents/${doc}/${element.file.name}`
                  let destination = path + `/documents/${doc}/${element.file.name}`;
                  let storage = await this.uploadDocAttachment(destination, element.file)
                  console.log(storage)
                  this.vehicle.documents[doc].storage = storage
                
                
              }

              if(element.deleted && element.changed === null) {
                  let url = this.vehicle.documents[doc].storage;
                  console.log(url)
                  await this.removeFileFromStorage(url)
                  this.vehicle.documents[doc].storage = null
              }
            
          }
        
        }

        for (let i = 0; i < this.otherLicenses.length; i++) {
        
            if(this.otherLicenses[i].changed) {
                let url = this.otherLicenses[i].attachment.storage;
                if(url) {
                    await this.removeFileFromStorage(url)
                }
                let timestamp = moment().format('x')
                let path = this.userStorageFolder(this.user)


                let destination = path + `/other-licenses/${timestamp + this.otherLicenses[i].name + this.otherLicenses[i].attachment.file.name }`
                //let destination = `users/`+ email +`/fleet/${this.$route.params.id}/other-licenses/${timestamp + this.otherLicenses[i].name + this.otherLicenses[i].attachment.file.name }`
                //`users/`+ email +`/fleet/${hash}/other-licenses/${this.otherLicenses[i].name}`
                let storage = await this.uploadDocAttachment(destination, this.otherLicenses[i].attachment.file)
                console.log(storage)
                this.otherLicenses[i].attachment.storage = storage
                this.otherLicenses[i].attachment.preview = null

                delete this.otherLicenses[i].attachment.file
                delete this.otherLicenses[i].changed

                


            }

            if(this.otherLicenses[i].deleted && this.otherLicenses[i].changed === null) {
                let url = this.otherLicenses[i].attachment.storage;
                console.log(url)
                await this.removeFileFromStorage(url)
               
            }
        }

        if(this.removedLicenses.length > 0){
            for (let i = 0; i < this.removedLicenses.length; i++) {
                
                if(this.removedLicenses[i].deleted && this.removedLicenses[i].changed === null) {
                    let url = this.removedLicenses[i].attachment.storage;
                    console.log(url)
                    await this.removeFileFromStorage(url)
                
                }
            }

        }

    },
    validateStep(step){
        // nombre
        // tipo vehicle
        // Pass
        // lugga

        // color
        // placa
        // año
        // marca
        // modelo

        switch(step) {
            case 'general':
                let name,passengers,luggage,pictures,base;
                name = this.$v.vehicle.name.$invalid;
                passengers = this.$v.vehicle.passengers.$invalid;
                luggage = this.$v.vehicle.luggage.$invalid;
                base = this.$v.vehicle.base.$invalid;
                


                this.tabs.general.errors = 0 ;
                name ? this.tabs.general.errors++ : false;
                passengers ? this.tabs.general.errors++ : false;
                luggage ? this.tabs.general.errors++ : false;
                base ? this.tabs.general.errors++ : false;
                
                

                return !name && !passengers && !luggage && !base ? true : false ;

                
            case 'mainInfo':
                let plate,
                brand,
                carModel,
                year,
                color
                
                plate = this.$v.vehicle.mainInfo.plate.$invalid;
                brand = this.$v.vehicle.mainInfo.brand.$invalid;
                carModel = this.$v.vehicle.mainInfo.carModel.$invalid;
                year = this.$v.vehicle.mainInfo.year.$invalid;
                color = this.$v.vehicle.mainInfo.color.$invalid;
                

                this.tabs.mainInfo.errors = 0 ;
                plate ? this.tabs.mainInfo.errors++ : false;
                brand ? this.tabs.mainInfo.errors++ : false;
                carModel ? this.tabs.mainInfo.errors++ : false;
                year ? this.tabs.mainInfo.errors++ : false;
                color ? this.tabs.mainInfo.errors++ : false;
                
            

                return !plate && !brand && !carModel && !year && !color ? true : false ;
            case 'documents':
                this.tabs.documents.errors = 0 ;
                let otherDocErrors = []
                    for (const doc in this.vehicle.documents) {
                    if (Object.hasOwnProperty.call(this.vehicle.documents, doc)) {
                        const element = this.vehicle.documents[doc];
                        

                        let fileName, expiration ;

                        fileName = this.$v.vehicle.documents[doc].fileName.$invalid
                        expiration = this.$v.vehicle.documents[doc].expiration.$invalid

                        fileName ? this.tabs.documents.errors++ : false;
                        expiration ? this.tabs.documents.errors++ : false;

                        !fileName && !expiration ? otherDocErrors.push(true) : otherDocErrors.push(false)
                        
                    }
                }
                    return otherDocErrors.includes(false) ? false : true ;
                
            case 'pictures':
                return true
            
            case 'otherLicenses':
                this.tabs.otherLicenses.errors = 0 ;
                let otherErrors = []
                for (let i = 0; i < this.otherLicenses.length; i++) {
                    
                    let licName,expiration,file;
                    licName = this.$v.otherLicenses.$each[i].name.$invalid;
                    expiration = this.$v.otherLicenses.$each[i].expiration.$invalid;
                    file = this.$v.otherLicenses.$each[i].attachment.file.$invalid;

                    licName ? this.tabs.otherLicenses.errors++ : false;
                    expiration ? this.tabs.otherLicenses.errors++ : false;
                    file ? this.tabs.otherLicenses.errors++ : false;

                    !licName && !expiration && !file  ? otherErrors.push(true) : otherErrors.push(false) ;
                    
                }

                

                return otherErrors.includes(false) ? false : true ;
            
        }                    
    },
    validateAllSteps(){
        let validSteps = [];
        let firstCorrectableStep = undefined;
        let steps = ['general','mainInfo','documents','pictures','otherLicenses'] ;
        for (let i = 0; i < steps.length; i++) {
            if(this.validateStep(steps[i])) {
                validSteps.push(true)
            } else {
                validSteps.push(false)
                if(firstCorrectableStep === undefined) {
                    firstCorrectableStep = steps[i] ;
                }
            }
                    
        }
        
        if(validSteps.includes(false)) {
            
            this.$refs.mainTabs.activeTab = firstCorrectableStep
            //this.mainActiveTab = 'general' ;
            this.$v.$touch();
            return false
        } else {
            console.log('All steps are valid')
            //this.add();
            //this.saveVehicle()
            return true
        }

        
    },
    async fireUpdate(){
        this.confirmDialog = false
        this.confirmLeave = true
        this.isBusy = true;

        if(this.validateAllSteps()) {
            await this.updateAttachments()
        
            if(this.removedPictures.length > 0) {
                await this.fireRemovedPictures()
            }
            await this.uploadPictures()
            
            this.update()

            await this.flushUserUpdates()
        }
        
        this.isBusy = false;
        
    },
    update() {
      
      let th = this;
      this.vehicle.otherLicenses = this.otherLicenses

      let doc = this.getDocPath(this.user,this.$route.params.id)
      //firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet').doc(this.$route.params.id).update(th.vehicle)
      doc.update(th.vehicle)
      .then(function(){
        console.log('Updated');
        th.snackMessage = 'Vehicle type updated';
        th.isBusy = false;
        th.showSnackbar = true;
        th.$router.push({path: `/app/fleet`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    async destroy() {
        this.confirmLeave = true;
        this.deleteDialog.active = false; 
        this.isBusy = true ;
        await this.removeEntireVehicleFolder()
        let th = this;
        let doc = this.getDocPath(this.user,this.$route.params.id)

        doc.delete()
        .then(async function(){
            await th.flushUserUpdates()
            console.log('Deleted');
            th.$router.push({path: `/app/fleet`})
            th.isBusy = false ;
        }).catch(function(error) {
            console.log("Error getting document:", error);
            th.isBusy = false ;
        });
    },
    getCanvasBlob(canvas) {
        return new Promise(function(resolve, reject) {
            canvas.toBlob(function(blob) {
            resolve(blob)
            },'image/jpeg')
        })
    },
    async uploadPictures() {
        let email = this.user.data.email ;
        
        
        const promises = this.files.map(async (file, index) => {
            console.log(this.$refs.cropper[index]);


            // const canvas = this.$refs.cropper[index].getCroppedCanvas()
            // let ref = this.$firebase.storage().ref()
            //             .child(`users/`+ email +`/fleet/${hash}/pictures/${file.file.name}`)
            // var blobOutput = await this.getCanvasBlob(canvas);

            
            // return ref.put(blobOutput).then(() => ref.getDownloadURL());

            var timestamp = moment().format('x')
            let options = {
                imageSmoothingQuality: 'high',
                minWidth: 854,
                minHeight: 480,
                fillColor: '#fff',
                maxWidth: 854,
                maxHeight: 480,
            }
            const canvas = this.$refs.cropper[index].getCroppedCanvas(options)

            let path = this.userStorageFolder(this.user)
            
            let ref = this.$firebase.storage().ref()
                        .child(path + `/pictures/picture-${index}-${timestamp}`)
                        //.child(`users/`+ email +`/fleet/${this.$route.params.id}/pictures/picture-${index}-${timestamp}`)
            var blobOutput = await this.getCanvasBlob(canvas);

            
            return ref.put(blobOutput).then(() => ref.getDownloadURL());
            
        })

        return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            this.files = [];
            for (let i = 0; i < uploadedMediaList.length; i++) {
                this.vehicle.pictures.push(uploadedMediaList[i]);
            }
             
        })
        .catch((err) => alert(err.code));

    },
    async removeAllPictures() {
      let email = this.user.data.email ;
      const promises = this.vehicle.pictures.map((picture, index) => {
            console.log(picture);
            let pictureRef = this.$firebase.storage().refFromURL(picture);
            return pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
              })
              .catch((err) => {
                console.log(err);
              });
      })
      return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            
        })
        .catch((err) => alert(err.code));

    },
    async fireRemovedPictures(){
        const promises = this.removedPictures.map((picture, index) => {
            console.log(picture);

            
            let pictureRef = this.$firebase.storage().refFromURL(picture);
            return pictureRef.delete()
            .then(() => {
                console.log('deleted successfully');
                
            })
            .catch((err) => {
                console.log(err);
            });
           
            
        })
        return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            
        })
        .catch((err) => alert(err.code));
    },
    removePicture(picture,isNew,index){
        console.log(picture);
        console.log(isNew);
        console.log(index);
        if(isNew) {
            this.files.splice(index,1)
        } else {
            this.removedPictures.push(this.vehicle.pictures[index])
            this.vehicle.pictures.splice(index,1);
            /*
            let url = this.vehicle.pictures[index];
            let pictureRef = this.$firebase.storage().refFromURL(url);

            
            pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
                this.vehicle.pictures.splice(index,1);
                this.update();

              })
              .catch((err) => {
                console.log(err);
              });
              */

        }
        
          
    },
    addPicture()
    {
        this.$refs.carfile.$refs.inputFile.click();
        
    },
    getValidationClass (fieldName,index) {
        let field = null;

        if(fieldName === 'files') {
            field = this.$v[fieldName];
        } else if(
            
            fieldName == 'mainInfo.plate' 
            || fieldName == 'mainInfo.brand' 
            || fieldName == 'mainInfo.carModel' 
            || fieldName == 'mainInfo.year' 
            || fieldName == 'mainInfo.color' 
        ){
            let fieldSplit = fieldName.split('mainInfo.')[1];
            field = this.$v.vehicle.mainInfo[fieldSplit]
        } else if (
            fieldName === 'documents.fileName'
            || fieldName === 'documents.expiration'
        ) {

                let fieldSplit = fieldName.split('documents.')[1];
            field = this.$v.vehicle.documents[index][fieldSplit]


        }
        
        
        else if(
            fieldName === 'otherLicenses.name'
            || fieldName === 'otherLicenses.file'
            || fieldName === 'otherLicenses.expiration'

        
        ) {
            
            let fieldSplit = fieldName.split('otherLicenses.')[1];
            if(fieldSplit == 'file') {
                field = this.$v.otherLicenses.$each[index].attachment[fieldSplit]
            } else {
                field = this.$v.otherLicenses.$each[index][fieldSplit]
            }
            
        } else {
            field = this.$v.vehicle[fieldName];
        }
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    validate(){
        let type,passengers,luggage,pictures,base;
        type = this.$v.vehicle.type.$invalid;
        passengers = this.$v.vehicle.passengers.$invalid;
        luggage = this.$v.vehicle.luggage.$invalid;
        base = this.$v.vehicle.base.$invalid;
        pictures = this.$v.files.$invalid;

        return !type && !passengers && !luggage && !pictures && !base ? true : false ;


    },
    imageChanged(e, type) {
        console.log(e.target);
        console.log(e.target.files);
        for (let i = 0; i < e.target.files.length; i++) {
                
            const file = e.target.files[i];
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {  
                    

                    let pushedIndex = this.files.push({
                        imgSrc: event.target.result,
                        file: file,
                        editing: false,
                        cropper:false
                    }) - 1 ;

                    console.log(pushedIndex)
                    this.$nextTick(() => {
                    
                        let width = this.$refs.cropperPreviewContainer[pushedIndex].offsetWidth
                        console.log(width)
                        //let height = ( 360 * width / 640 ) + 'px' 
                        let height = width * ( 9 / 16) + 'px'
                        this.$refs.cropperPreviewContainer[pushedIndex].firstChild.style.width = width + 'px'
                        this.$refs.cropperPreviewContainer[pushedIndex].firstChild.style.height = width * ( 9 / 16) + 'px'


                       
                        this.files[pushedIndex].cropper = true
                        this.files[pushedIndex].editing = true
                        
                        setTimeout(() => {
                            let container = this.$refs.cropper[pushedIndex].getContainerData()
                            let canvas = this.$refs.cropper[pushedIndex].getCanvasData()
                            let cropbox = this.$refs.cropper[pushedIndex].getCropBoxData()
                            
                            let left = ( container.width -  canvas.width ) / 2
                            console.log(left)
                             let data = {
                                width: width,
                                height: width * ( 9 / 16),
                                //width:200,
                                //height:100,
                                left:left,
                                top:0
                            }
                            this.$refs.cropper[pushedIndex].setCropBoxData(data)
                        },500)
                        
                        
 
                    })

                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        }

        
        this.$refs.carfile.$refs.inputFile.value = '';
        
        
    
    },
    mainTabChanged(tabId) {
    //console.log(tabId);
        //console.log('this.$refs.mainTabs');
        //console.log(this.$refs.mainTabs);
    },
    checkTimeToGo(date) {
        var startTime = moment();
        //var endTime = moment.unix(date);
        var endTime = moment(date);
        
        //var hoursDiff = endTime.diff(startTime, 'hours');
        
        var minutesDiff = endTime.diff(startTime, 'minutes');
        var daysDiff = endTime.diff(startTime, 'days');
        
        //var secondsDiff = endTime.diff(startTime, 'seconds');

        var ms = endTime.diff(startTime);
        var d = moment.duration(ms);
        //var s = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
        let className ;
        // if(minutesDiff >= 180) {
        //     className = 'white-pu'
        // } else if(minutesDiff >= 60 ) {
        //     className = 'yellow-pu'
        // } else if(minutesDiff > 0) {
        //     className = 'red-pu'
        // }

        

        if(daysDiff < 0  || minutesDiff < 0) {
            className = 'up-text-cancelled'
        } else if(daysDiff >= 0 && daysDiff <= 30) {
            className = 'up-text-caution'
        } else if(daysDiff > 30) {
            className = 'up-text-confirmed'
        }

        let payload ;
        // if(d >= 0 ) {
        //     payload = '<div class="time-to-go ' + className + '">' + d.days() + ' D, ' + d.hours() + ' H, ' + d.minutes() + ' M' + ' to PU' + '</div>';
        //     //return 'Days:' + d.days() + ' , Hours:' + d.hours() + ' , Minutes:' + d.minutes() + ' to pick up';
        // } else {
        //     // Here goes logic with trip status , on location, dropped off etc
        //     className = 'green-pu'
        //     className = 'grey-pu'
        //     if(minutesDiff < 0 &&  minutesDiff >= -60) {
        //     payload = '<div class="time-to-go ' + className + '">' + 'Picked up' + '</div>';
        //     //payload = '<div class="' + className + '">' + d.days() + ' days, ' + d.hours() + ' h and ' + d.minutes() + ' min' + ' to PU' + '</div>';
        //     } else {
        //     payload = ''
        //     }
        
        // }
        let text = minutesDiff >= 0 ? 'Expires ' : 'Expired '  
        payload = '<div class="text-uppercase time-to-go ' + className + '">'+ text + endTime.fromNow() + '</div>'
        return payload ;
        //return ''
        //moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    openDocDialog(name,src,image,pdf) {

        
        this.docDialog.page = 1
        this.docDialog.name = name
        this.docDialog.src = src
        
        this.docDialog.image = image
        this.docDialog.pdf = pdf
        this.docDialog.active = true
        this.$nextTick(() => {
            
            
            let pdf = this.$refs.masterPdf
            if(pdf) {
                this.docDialog.pageCount = this.$refs.masterPdf.pageCount
            }
            
            
            
            this.$store.commit('centerDialog',window.innerWidth)
        })
        
    },
    docAttachLicenseChanged(e, index) {
        console.log(e)
        console.log(e.target.files[0].type)
        
        this.otherLicenses[index].changed = true;
        if(e.target.files[0].type == 'application/pdf') {
            this.otherLicenses[index].attachment.pdf = true
            this.otherLicenses[index].attachment.image = false
        } else {
            this.otherLicenses[index].attachment.pdf = false
            this.otherLicenses[index].attachment.image = true
        }
        console.log(index)
        this.otherLicenses[index].attachment.file = e.target.files[0]
        this.otherLicenses[index].attachment.preview = URL.createObjectURL(e.target.files[0]);
        
    },
    docImageChanged(e, type) {
        console.log(e)
        console.log(e.target.files[0].type)
        this.pictures[type].changed = true
        if(e.target.files[0].type == 'application/pdf') {
            this.pictures[type].pdf = true
            this.pictures[type].image = false

            this.vehicle.documents[type].pdf = true
            this.vehicle.documents[type].image = false

        } else {
            this.pictures[type].pdf = false
            this.pictures[type].image = true

            this.vehicle.documents[type].pdf = false
            this.vehicle.documents[type].image = true
        }
        console.log(type)
        this.pictures[type].file = e.target.files[0]
        this.pictures[type].preview = URL.createObjectURL(e.target.files[0]);
        
    },
    
    myEventHandler(e) {
        // your code for handling resize...
        this.$store.commit('centerDialog',window.innerWidth)  
        
        ////
    },
    nextPdfPage() {
        console.log(this.$refs.masterPdf.pageCount)
        this.docDialog.pageCount = this.$refs.masterPdf.pageCount
        if(this.docDialog.pageCount > this.docDialog.page) {
            this.docDialog.page =  this.docDialog.page + 1
        }
        
    },
    prevPdfPage() {
        if(this.docDialog.page > 1) {
            this.docDialog.page =  this.docDialog.page - 1
        }
        
    },
  },
  validations: {
        files: {
            required,
            minLength: minLength(1),
        },
        vehicle: {
            name: {
                required
            },
            base: {
                required
            },
            passengers: {
                required,
                between (value) {
                    return between(1, this.selectedBase.passengers)(value)
                }
                
            },
            luggage: {
                required,
                between (value) {
                    return between(1, this.selectedBase.luggage)(value)
                }
            },
            // pictures: {
            //     required,
            //     minLength: minLength(1),
            // },
            documents: {
                fh1: {
                    expiration: {
                        required: requiredIf(function(){
                            return this.vehicle && !this.vehicle.documents.fh1.fileName ? false : true;
                            console.log(this.vehicle.documents)
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return this.vehicle && !this.vehicle.documents.fh1.expiration ? false : true;
                            console.log(this.vehicle.documents)
                         }),
                    }
                },
                
                
                registration: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.registration.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.registration.expiration ? false : true;
                         }),
                    }
                },
                tlc: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.tlc.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.tlc.expiration ? false : true;
                         }),
                    }
                },
                dmv: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.dmv.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.dmv.expiration ? false : true;
                         }),
                    }
                },
                liability: {
                    expiration: {
                        required: requiredIf(function(){
                             //return !this.vehicle.documents.liability.fileName ? false : true;
                             return false
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.liability.fileName ? false : true;
                         }),
                    }
                }

            },
            // documents: {
            //     fh1ExpirationDate: {
            //         required
            //     },
            //     fh1Picture: {
            //         required
            //     },
            //     registrationExpirationDate: {
            //         required
            //     },
            //     registrationPicture: {
            //         required
            //     },
            //     tlcWindowStickerExpiration: {
            //         required
            //     },
            //     dmvInspectionStickerExpiration: {
            //         required
            //     },
            //     liabilityInsuranceWithDriverListedPicture: {
            //         required
            //     },
                
            //     tlcWindowStickerPicture: {
            //         required
            //     },
            //     dmvInspectionStickerPicture: {
            //         required
            //     }

            // },
            mainInfo: {
                plate: {
                    required
                },
                brand: {
                    required
                },
                carModel: {
                    required
                },
                year: {
                    required
                },
                color: {
                    required
                },
                
            }
        },
        otherLicenses: {
            $each: {
                name: {
                    required
                },
                expiration: {
                    //required

                    required: requiredIf(function(value){
                        return value.attachment.storage || value.attachment.file ? true : false
                        //return  true;
                    }),
                },
                attachment: {
                    //preview: null,
                    file:{
                        required: requiredIf(function(value){
                            
                           return value.storage ? false : true 
                           //return this.otherLicenses[0].attachment.storage ? false : true 
                           
                        })
                    },
                    //pdf: false,
                    //image: false
                }
            }
        }
    }
}

</script>
<style>

 .badge {
    width: 19px;
    height: 19px;
    line-height:19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
}

.badge.md-primary {
    background-color: rgb(119, 207, 119);
}

.badge.md-primary .md-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    font-size: 12px!important;
    color:white!important;

}


.cropper-preview-0 {
    /*
    width: 640px;
    height: 360px;
    */
    overflow: hidden;
   
    /*
    640px  100%
    360px height

    */

}
.buttons-container .md-button{
    min-width: initial;
}
.VueCarousel-pagination {
    text-align: left!important;
}

.VueCarousel-slide-adjustableHeight {
    display: block !important;
}
.VueCarousel-inner {
    align-items: flex-start !important;
}

.VueCarousel-dot-container {
    margin-top: 0px!important;
}

.VueCarousel-dot {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}

img[lazy=loading] {
    /*your style here*/
    height: 300px;
  }

  
</style>

